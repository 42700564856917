<template>
  <b-tab>
    <b-card no-body>
      <div class="m-2">
        <div>
          <h4>Step 1: Upload CSV from Gusto Pay Contractors</h4>
          <ul>
            <li>Visit <a href="https://app.gusto.com/contractors/pay/domestic" target="_blank">Gusto Pay Contractors</a></li>
            <li>Click on <b>Upload CSV</b></li>
            <li>Click on <b>Download CSV template</b></li>
            <li><b>Upload</b> the CSV file to this page</li>
          </ul>
        </div>
        <b-row class="align-items-center justify-content-center">
          <b-col cols="12" md="6" class="mb-3">
            <div class="d-flex align-items-around justify-content-around">
              <b-form-file
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".csv, .CSV"
                size="lg"
                @input="submit"
              ></b-form-file>
              <b-button class="mx-2" variant="primary" @click="file = null">Reset</b-button>
            </div>
            <div class="mt-3">Selected file: {{ file ? file.name : '' }}</div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-tab>
</template>
<script>
  import { BTab, BCard, BRow, BCol, BButton, BFormFile } from "bootstrap-vue";
  export default {
    components: {
      BTab,
      BCard,
      BRow,
      BCol,
      BButton,
      BFormFile,
    },
    setup() {
      const base64Encode = data =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      
      const base64Decode = data => {
        data = data.replace(/^data:.+\;base64,/, '')
        return Buffer.from(data, 'base64').toString('utf-8')
      }
      return {
        base64Encode,
        base64Decode,
      };
    },
    data() {
      return {
        file: null,
      }
    },
    methods: {
      async submit(file) {
        if (file) {
          const file64 = await this.base64Encode(file)
          const fileDecoded = await this.base64Decode(file64)
          this.$emit('fileUpload', fileDecoded)
        }
      },
    }
  }
</script>