<template>
  <b-tab>
    <b-card no-body>
      <div class="m-2">
        <div>
          <h4>Step 2: Verify and Save Information</h4>
          <ul>
            <li>If you want to change the employee information, click on <b>selector</b> and select the employee</li>
            <li>Review the information and click on <b>Save Changes</b></li>
          </ul>
        </div>
      </div>
      <div>
        <b-table
          ref="refListPayments"
          class="position-relative"
          :items="preview"
          responsive
          striped
          sortable
          hover
          show-empty
          empty-text="No matching records found"
          :fields="tableColumns"
        >
          <!-- Column: relationId -->
          <template #cell(relationId)="data">
            <v-select
              v-model="data.item.relationId"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="returnOptions()"
              :reduce="(val) => val.value"
              :clearable="false"
            />
          </template>
        </b-table>
      </div>

      <div>
        <b-row class="align-items-center justify-content-center">
          <b-col cols="12" md="6" class="mb-3">
            <div class="d-flex align-items-around justify-content-around">
              <b-button class="mx-2" variant="primary" @click="$emit('changeStep', 0)">Back</b-button>
              <b-button class="mx-2" variant="primary" @click="$emit('finish', preview)">Save Changes</b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-tab>
</template>
<script>
  import { BTab, BCard, BRow, BCol, BButton, BTable } from "bootstrap-vue";
  import vSelect from "vue-select";
  export default {
    components: {
      BTab,
      BCard,
      BRow,
      BCol,
      BButton,
      BTable,
      vSelect
    },
    props: {
      preview: {
        type: Array,
        required: true,
      },
      employeesList: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        tableColumns: [
          {
            key: 'contractor_type',
            label: 'Type',
            sortable: true,
          },
          {
            key: 'first_name',
            label: 'First Name',
            sortable: true,
          },
          {
            key: 'last_name',
            label: 'Last Name',
            sortable: true,
          },
          {
            key: 'ssn',
            label: 'SSN',
            sortable: true,
          },
          {
            key: 'business_name',
            label: 'Business Name',
            sortable: true,
          },
          {
            key: 'ein',
            label: 'EIN',
            sortable: true,
          },
          {
            key: 'relationId',
            label: 'Employee Melabeauty',
            sortable: true,
          },
        ],
      }
    },
    methods: {
      returnOptions() {
        const options = [{ value: null, label: 'Create Employee' }];
        for (const employee of this.employeesList) {
          options.push({ value: employee.id, label: employee.first_name + ' ' + employee.last_name });
        }
        return options;
      }
    }
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
</style>