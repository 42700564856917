import store from "@/store";

// Notification
import { createToastInterface } from "vue-toastification";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const toast = createToastInterface();

export function workerUpload () {
  const USER_APP_STORE_MODULE_NAME = "app-payroll";

  const sendCsv = (office, content) => {
    const data = {
      content: content,
    }
    return store.dispatch(`${USER_APP_STORE_MODULE_NAME}/uploadCsvSync`, { officeId: office.value || office, data: data })
      .then((data) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'CSV Successfully Sent',
            icon: "AlertCircleIcon",
            variant: "success",
          },
        });
        return data;
      })
      .catch((error) => {
        let errorMsg = "Failed to send csv";
        if (error.response) {
          if (error.response.data.error) {
            errorMsg = error.response.data.error;
          }
        }
        toast({
          component: ToastificationContent,
          props: {
            title: errorMsg,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        throw error;
      });
  }

  const confirm = (office, content) => {
    const data = {
      content: content,
    }
    return store.dispatch(`${USER_APP_STORE_MODULE_NAME}/confirmSync`, { officeId: office.value || office, data: data })
      .then((data) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully Confirmed',
            icon: "AlertCircleIcon",
            variant: "success",
          },
        });
        return data;
      })
      .catch((error) => {
        let errorMsg = "Failed to send csv";
        if (error.response) {
          if (error.response.data.error) {
            errorMsg = error.response.data.error;
          }
        }
        toast({
          component: ToastificationContent,
          props: {
            title: errorMsg,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        throw error;
      });
  }

  return {
    sendCsv,
    confirm,
  }
}