<template>
  <div>
    <!-- Filters -->
    <Filters
      :officeFilter.sync="officeFilter"
      :officeOptions="officeList"
    />

    <b-tabs v-model="step">
      <TabVerify @changeStep="changeStep" @fileUpload="uploadCsv" />
      <TabSave @changeStep="changeStep" @finish="finish" :preview.sync="preview" :employeesList.sync="employeesList" />
    </b-tabs>
  </div>
</template>
<script>
  import store from "@/store";
  import router from "@/router";
  import { ref, onUnmounted, watch } from "@vue/composition-api";
  import { BTabs } from "bootstrap-vue";

  // Methods
  import { hasValidationGusto } from './../validationGusto';
  import payrollStoreModule from '../payrollStoreModule';
  import { workerUpload } from './worker-upload'

  // Components
  import Filters from './FilterUpload.vue'
  import TabVerify from './StepVerify.vue'
  import TabSave from './StepSave.vue'
  export default {
    components: {
      Filters,
      TabVerify,
      TabSave,
      BTabs,
    },
    setup() {
      const USER_APP_STORE_MODULE_NAME = "app-payroll";

      // Register module
      if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
        store.registerModule(USER_APP_STORE_MODULE_NAME, payrollStoreModule);
      }

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME)) {
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        }
      });

      const { officeList, officeFilter, readyForStart } = hasValidationGusto()
      return {
        officeList: officeList,
        officeFilter: officeFilter,
        readyForStart: readyForStart,
      }
    },
    data() {
      return {
        step: 0,
        preview: [],
        employeesList: [],
      }
    },
    methods: {
      changeStep(step) {
        this.step = step || 0
      },
      async uploadCsv(content) {
        if (!content) {
          return;
        }
        const data = await workerUpload().sendCsv(this.officeFilter, content);
        this.employeesList = data.employees;
        this.preview = data.result;
        this.changeStep(1);
      },
      async finish() {
        await workerUpload().confirm(this.officeFilter, this.preview);
        router.push({ name: "apps-payroll-payments" });
      },
    },
  }
</script>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  ul.nav.nav-tabs {
    display: none !important;
  }
</style>